//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


//*-----------------------------------------------
//|   Fonts Families
//-----------------------------------------------*/
$font-family-sans-serif:Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";


//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/
$red: #DE2E17 !default;
$orange: #F04D17 !default;

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: $red !default;