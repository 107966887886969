// 
// 
// user.scss
//
// Place your own sparrow CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

/*-----------------------------------------------
|   Font Import
-----------------------------------------------*/
@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Light.eot');
  src: url('/assets/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Lato-Light.woff2') format('woff2'),
      url('/assets/fonts/Lato-Light.woff') format('woff'),
      url('/assets/fonts/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Bold.eot');
  src: url('/assets/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Lato-Bold.woff2') format('woff2'),
      url('/assets/fonts/Lato-Bold.woff') format('woff'),
      url('/assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Regular.eot');
  src: url('/assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Lato-Regular.woff2') format('woff2'),
      url('/assets/fonts/Lato-Regular.woff') format('woff'),
      url('/assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-LightItalic.eot');
  src: url('/assets/fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Lato-LightItalic.woff2') format('woff2'),
      url('/assets/fonts/Lato-LightItalic.woff') format('woff'),
      url('/assets/fonts/Lato-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Italic.eot');
  src: url('/assets/fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Lato-Italic.woff2') format('woff2'),
      url('/assets/fonts/Lato-Italic.woff') format('woff'),
      url('/assets/fonts/Lato-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/*-----------------------------------------------
|   Fancy Navbar Collapse
-----------------------------------------------*/
.fancynavbar-collapse {
  background-color: $primary;
}

/*-----------------------------------------------
|   Fancy Navbar Brand
-----------------------------------------------*/
.fancynavbar-brand {
  padding: 0 map_get($spacers, 3);
  @include media-breakpoint-up(md) {
	padding:0;
  }
}
.fancynavbar-brand img {
  @include media-breakpoint-up(md) {
	width:46px;
	height:auto;
  }
}

// Adding a pure css loader to allow dropping off the excessive css and js file required
// to generate the current one.
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*-----------------------------------------------
|   Fancy Navbar Link Content
-----------------------------------------------*/
.fancynav-link-content {
  color: $gray-200;
  background-color: $primary;
}

/*-----------------------------------------------
|   Fancy Navbar Link
-----------------------------------------------*/
.fancynav-link {
  &:before {
    background-color: $gray-200;
  }
  &.fancy-dropdown-toggle .fancynav-link-content {
    &:after {
      background-color: $primary;
    }
  }
}

/*-----------------------------------------------
|   Fancy Dropdown Item
-----------------------------------------------*/
.fancy-dropdown-item {
  color: $gray-200;
}
.fancy-dropdown-item-child {
    margin-left: map_get($spacers, 3);
}